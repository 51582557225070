<template>
  <TyresStyled>
    <PanelTableMolecule
      :isLoading="loading"
      :count="totalCount"
      :title="$tc('tyre', 2) | capitalize"
      :columns="columns"
      :rows="tableData"
      :pageIndex="page + 1"
      :pageSize="pageSize"
      :createRouteName="'createTyre'"
      @pageNumberChange="pageNumberChange($event)"
      @reload="reload"
    />
    <router-view @update-tyres-list="reload" />
  </TyresStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'
import TableColumnSearch from '@/components/Atomic/Molecules/TableColumnSearch'
import { Edit2Icon, SearchIcon } from 'vue-feather-icons'

import TYRES_CONNECTION_QUERY from '#/graphql/tyres/tyresConnection.gql'
import EntityLinkAtom from '@/components/Atomic/Atoms/EntityLinkAtom'

const TyresStyled = styled('div')`
  margin: 1rem;
`

export default {
  components: {
    TyresStyled,
    PanelTableMolecule,
  },
  data() {
    return {
      loading: true,
      totalCount: 0,
      models: [],
      searchQuery: '',
      pageSize: 10,
      page: 0,
      perPage: 10,
      where: {},
    }
  },
  computed: {
    columns() {
      const header = [
        {
          field: 'serialNumber',
          key: 'serialNumber',
          title: 'Serial Number',
          align: 'left',
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.searchQuery}
                  placeholder="Search name"
                  on-valueChange={e => (this.searchQuery = e)}
                  on-cancel={() => this.searchCancel(closeFn)}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
          renderBodyCell: ({ row }) => {
            const { id, type, serialNumber } = row
            if (!id || !type || !serialNumber) {
              return `something missing`
            }
            return <EntityLinkAtom type={type} id={id} label={serialNumber} />
          },
        },
        {
          field: 'asset',
          key: 'asset',
          title: 'Asset',
          align: 'center',
          renderBodyCell: ({ row }) => {
            if (!row?.asset) {
              return '-'
            }
            const { id, __typename, name } = row.asset
            if (!id || !__typename || !name) {
              return `something missing`
            }
            return <EntityLinkAtom type={__typename} id={id} label={name} />
          },
        },
        { field: 'model', key: 'model', title: 'Model', align: 'center' },
        { field: 'usable', key: 'usable', title: 'Usable', align: 'center' },
        {
          field: 'actions',
          key: 'i',
          title: 'Actions',
          width: '',
          center: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <span class="actions">
                <router-link to={`/assets/tyres/${row?.rowKey}`}>
                  <Edit2Icon size="1.3x" />
                </router-link>
              </span>
            )
          },
        },
      ]
      return header
    },
    tableData() {
      const tableData = this.models.map(tyre => {
        return {
          serialNumber: tyre.serialNumber,
          // asset: tyre.asset ? tyre.asset.id : '-',
          model: tyre.model,
          usable: tyre.isEndOfLife ? 'NO' : 'YES',
          rowKey: tyre.id,
          id: tyre.id,
          type: tyre.__typename,
          asset: tyre.asset,
        }
      })
      return tableData
    },
    skip() {
      return this.page * this.pageSize
    },
  },
  methods: {
    reload() {
      this.searchQuery = ''
      this.$apollo.queries.models.refetch()
    },
    pageNumberChange(pageIndex) {
      this.page = pageIndex - 1
    },
    searchCancel(closeFn) {
      this.searchQuery = ''
      closeFn()
    },
    searchConfirm(closeFn) {
      closeFn()
    },
  },
  watch: {
    searchQuery: {
      handler(s) {
        this.page = 0
        if (s) {
          this.where = {
            serialNumber: {
              contains: s,
              mode: 'insensitive',
            },
          }
        } else {
          this.where = undefined
        }
      },
    },
  },
  apollo: {
    models: {
      query: TYRES_CONNECTION_QUERY,
      variables() {
        return {
          take: this.perPage,
          skip: this.skip,
          where: this.where,
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.models = get(data, 'tyres')
        this.totalCount = get(data, 'aggregateTyre._count._all', 0)
      },
    },
  },
}
</script>
